import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const cta = ({ href }) => (
  <a href={href} alt="1G Solutions">
    <StaticImage
      className="rounded-lg shadow-lg"
      src="../images/cta/CTA-1G-Solutions-Geotechnique-Maison.png"
      alt="1G Solutions"
    />
  </a>
);

export default cta;
