import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";
import { CheckIcon } from "@heroicons/react/solid";
import { encode } from "js-base64";

import Header from "src/components/headers/header";
import Ariane from "src/components/ariane";
import Footer from "src/components/footers/footer";
import FormExpert from "src/components/formulaires/formExpert";

import CTA1GSolutions from "src/components/cta/CTA1GSolutions";
const shortcodes = {
  CTA1GSolutions,
};



const MdxPage = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: {
        date,
        slug,
        title,
        title_meta,
        description_meta,
        excerpt,
        thumbnailText,
        embeddedImagesLocal,
        fil_ariane,
      },
      body,
    },
  },
}) => {
  const encryptedMail = encode("b.bagayoko@novinntec.fr");
  // const encryptedMail = encode("vinh@koudepouce.fr");
  const moyen = "Formulaire Geotechnique maison Geo Novinntec";

  return (
    <div className="bg-white">
      <Helmet>
        <title>{title_meta}</title>
        <meta name="description" content={description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
        <Ariane fil_ariane={fil_ariane} />
      </header>

      <div class="relative max-w-7xl mx-auto h-full lg:flex">
        <main className="">
          <div class="flex flex-1 overflow-hidden pr-4">
            <div class="flex flex-1 flex-col">
              <div class="flex flex-1 bg-white overflow-y-auto paragraph">
                <div className="relative py-16 bg-white overflow-hidden">
                  <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="text-lg max-w-prose mx-auto">
                      <h1>
                        <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                          {title}
                        </span>
                      </h1>
                      <p className="mt-8 mb-8 text-xl text-gray-500 leading-8"></p>

                      <GatsbyImage
                        className="rounded-lg shadow-lg"
                        alt={thumbnailText}
                        image={getImage(embeddedImagesLocal[0])}
                      />
                    </div>

                    <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
                      <MDXProvider components={shortcodes}>
                        <MDXRenderer
                          className="mt-6 prose prose-slate prose-lg text-gray-500 mx-auto"
                          remoteImages={embeddedImagesRemote}
                          localImages={embeddedImagesLocal}
                        >
                          {body}
                        </MDXRenderer>
                      </MDXProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <aside class="self-start top-16 col-span-1 p-4 lg:py-16 lg:w-1/3">
          <div className="bg-white ">
            <div className="border border-gray-200 mb-8 rounded-lg bg-white shadow-lg flex flex-col flex-grow text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
              <div className="text-center mb-4 text-xl font-bold">
                <p>Etude de sol</p>
              </div>
              <a href="/entreprises/novinntec/">
                <div className="mx-auto h-40 w-40 rounded-full">
                  <StaticImage
                    className="mx-auto h-40 w-40 rounded-full"
                    src="../images/batiments/novinntec.png"
                    alt="Novinntec"
                  />
                </div>
                <h3 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">
                  Novinntec
                </h3>
                <p className="mt-2 text-base leading-7 text-gray-600">
                  {/* <strong>Damien Zdravkovic</strong>
                <br /> */}
                  60 Rue François 1er
                  <br />
                  75008 Paris
                  <br />
                  01 87 66 42 16
                </p>
              </a>

              <FormExpert encryptedMail={encryptedMail} moyen={moyen} />
            </div>
          </div>

          <div className="text-leftmb-4 text-xl font-bold">
            <p>A propos de Novinntec</p>
          </div>
          <ul
            role="list"
            className="mt-8 space-y-3 text-sm leading-6 text-gray-600 pl-8"
          >
            <li className="flex gap-x-3">
              <CheckIcon
                className="h-6 w-5 flex-none text-orange-600"
                aria-hidden="true"
              />
              Investigations géotechniques
            </li>
            <li className="flex gap-x-3">
              <CheckIcon
                className="h-6 w-5 flex-none text-orange-600"
                aria-hidden="true"
              />
              Périmètres adaptés aux enjeux
            </li>
            <li className="flex gap-x-3">
              <CheckIcon
                className="h-6 w-5 flex-none text-orange-600"
                aria-hidden="true"
              />
              Équipes partout en France
            </li>
            <li className="flex gap-x-3">
              <CheckIcon
                className="h-6 w-5 flex-none text-orange-600"
                aria-hidden="true"
              />
              Etude de sol
            </li>
          </ul>
          {/* <Reassurance /> */}
        </aside>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        reference
        date
        slug
        fil_ariane
        title
        title_meta
        description_meta
        excerpt
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;
